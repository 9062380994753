import { COOKIE_KEYS } from 'libs/consts'

type ApiVersion = 'v1' | 'v2' | 'bff'

type Params = {
	version?: ApiVersion
}

const API_PATH: Record<ApiVersion, string> = {
	v1: process.env.NEXT_PUBLIC_INTERNAL_BASE_API_URI as string,
	v2: process.env.NEXT_PUBLIC_INTERNAL_BASE_API_URI_2 as string,
	bff: '/api',
}

export function getBaseApiUrl({ version }: Params = { version: 'v1' }) {
	const apiPath = API_PATH[version ?? 'v1']

	if (version === 'bff') {
		return { url: `${process.env.NEXT_PUBLIC_BASE_URL}${apiPath}`, path: apiPath }
	}

	return { url: `${process.env.BASE_API_URL || process.env.NEXT_PUBLIC_BASE_URL}${apiPath}`, path: apiPath }
}

function getAnonymousIdFromSetCookie(setCookie?: string | number | string[]) {
	if (!setCookie) {
		return
	}

	let anonymousIdCookie: string | null = null

	const cookieList = Array.isArray(setCookie) ? setCookie : [String(setCookie)]
	const anonymousIdCookieRaw = getAnonymousIdFromCookie(cookieList)

	if (anonymousIdCookieRaw) {
		anonymousIdCookie = anonymousIdCookieRaw.split('; ').reduce((acc, c) => {
			const [name, value] = c.split('=')

			if (name === COOKIE_KEYS.anonymousIdInternal) {
				return value
			}

			return acc
		}, '')
	}

	if (anonymousIdCookie) {
		return `${COOKIE_KEYS.anonymousIdInternal}=${anonymousIdCookie}`
	}
}

function addAnonymousIdCookie(cookieRaw: string, anonymousIdCookie: string) {
	const cookieList = cookieRaw.split('; ')

	if (getAnonymousIdFromCookie(cookieList)) {
		return cookieRaw
	}

	cookieList.push(anonymousIdCookie)

	return cookieList.join('; ')
}

function getAnonymousIdFromCookie(cookie: string[]) {
	return cookie.find((c) => c.startsWith(`${COOKIE_KEYS.anonymousIdInternal}=`))
}

export function handleSetCookieAnonymousId(cookie?: string, setCookie?: string | number | string[]) {
	const anonymousIdCookie = getAnonymousIdFromSetCookie(setCookie)

	if (!anonymousIdCookie) {
		return cookie
	}

	if (!cookie) {
		return anonymousIdCookie
	}

	return addAnonymousIdCookie(cookie, anonymousIdCookie)
}
