export function logErrorDebug(...args: Parameters<typeof console.error>) {
	if (process.env.DEBUG !== 'true' && process.env.NEXT_PUBLIC_DEBUG !== 'true') {
		return
	}

	// eslint-disable-next-line no-console
	console.error('[DEBUG ERROR]', ...args)
}

export function logDebug(...args: Parameters<typeof console.log>) {
	if (process.env.DEBUG !== 'true' && process.env.NEXT_PUBLIC_DEBUG !== 'true') {
		return
	}

	// eslint-disable-next-line no-console
	console.log('[DEBUG LOG]', ...args)
}
