import type { IncomingHttpHeaders, IncomingMessage, ServerResponse } from 'http'

import { axios } from './redaxios-fork'
import { getBaseApiUrl, handleSetCookieAnonymousId } from './utils'

function getDefaultHttpHeaders(name: string) {
	const headers: IncomingHttpHeaders = {}

	headers['X-Client-Name'] = `Next.js ${name}`

	if (process.env.HTTP_HOST) {
		headers['Host'] = process.env.HTTP_HOST
	}

	if (process.env.X_FORWARDED_HOST) {
		headers['X-Forwarded-Host'] = process.env.X_FORWARDED_HOST
	}

	if (!headers['X-Forwarded-Proto'] || headers['X-Forwarded-Proto'] === 'http') {
		headers['X-Forwarded-Proto'] = 'https'
	}

	return headers
}

const IP_HEADERS = [
	'cf-ipcountry',
	'x-original-forwarded-for',
	'cf-connecting-ip',
	'x-forwarded-for',
	'user-agent',
	'x-real-ip',
]

function getIpHeaders(reqHeaders?: IncomingHttpHeaders) {
	const headers = IP_HEADERS.reduce((acc, cur) => {
		if (reqHeaders?.[cur]) {
			acc[cur] = reqHeaders[cur]
		}

		return acc
	}, {} as IncomingHttpHeaders)

	return headers
}

export function createStaticApiTransport() {
	if (typeof window !== 'undefined') {
		throw new Error("You can't use Static API transport on client side")
	}

	const headers = getDefaultHttpHeaders('Static Client')

	return axios.create({
		baseURL: getBaseApiUrl().url,
		headers,
		retry: true,
	})
}

// TODO: rename to createBrowserDirectApiTransport and make as deprecated, use createBrowserProxyApiTransport
/** @deprecated */
export function createBrowserApiTransport() {
	if (typeof window === 'undefined') {
		throw new Error('You can use Client-side API transport on client side only')
	}

	const headers = getDefaultHttpHeaders('Browser Client')

	return axios.create({
		baseURL: getBaseApiUrl().url,
		headers,
		retry: true,
		credentials: 'include',
	})
}

// TODO: rename to createBrowserApiTransport as default with proxy
export function createBrowserProxyApiTransport() {
	if (typeof window === 'undefined') {
		throw new Error('You can use Client-side API transport on client side only')
	}

	const headers = getDefaultHttpHeaders('Browser Client')

	return axios.create({
		baseURL: getBaseApiUrl({ version: 'bff' }).url,
		headers,
		retry: true,
		credentials: 'include',
	})
}

export function createApiTransport(
	req: IncomingMessage & { cookies: Partial<{ [key: string]: string }> },
	res?: ServerResponse,
	{ token, url }: { token?: string; url?: string } = {}
): TransportInstance {
	const baseApiUrl = getBaseApiUrl()
	const baseURL = url || baseApiUrl.url
	const setCookie = res?.getHeader('Set-Cookie')

	let auth: string | undefined

	const headers = { ...getDefaultHttpHeaders('Server Client'), ...getIpHeaders(req?.headers) }

	if (req?.headers?.authorization) {
		auth = req.headers.authorization
	}

	if (req?.cookies?.token || token) {
		auth = `JWT ${req?.cookies.token || token}`
	}

	if (req?.headers?.cookie) {
		headers.cookie = req.headers.cookie
	}

	if (setCookie) {
		headers.cookie = handleSetCookieAnonymousId(headers.cookie, setCookie)
	}

	return axios.create({
		baseURL,
		headers,
		auth,
		retry: true,
	})
}

export type TransportInstance = typeof axios
