import { RequestError } from 'infrastructure/legacy-api-transport'

export function logError(err: Error | unknown, extra?: Record<string, unknown>): void {
	import('@sentry/nextjs').then((Sentry) => {
		Sentry.init({
			dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
			environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
			release: process.env.NEXT_PUBLIC_RELEASE,
			// ignores Outlook scanning errors
			ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
		})

		Sentry.withScope((s) => {
			if (extra) {
				Object.entries(extra).forEach(([k, v]) => s.setExtra(k, v))
			}

			if (typeof err === 'string') {
				Sentry.captureMessage(err)
				return
			}

			if (err instanceof RequestError) {
				if ([404, 401].includes(err.status)) {
					return
				}

				s.setContext('response', err.meta)
			}

			Sentry.captureException(err)
		})
	})
}
